@import 'assets/scss/index.scss';

.table {

    &Columns {
        display: flex;
        margin-top: var(--exo-spacing-2x-small);
        border-radius: var(--exo-radius-large);
        overflow: hidden;
        border: var(--exo-spacing-4x-small) solid transparent;
        transform: border var(--exo-time-standard) ease;

        &_column:nth-of-type(1) {
            @include display($flex-grow: 0, $flex-basis: 50px);
        }
        &_column:nth-of-type(2) {
            @include display($flex-grow: 2, $flex-basis: 115px);
        }
        &_column:nth-of-type(3) {
            @include display($flex-grow: 1, $flex-basis: 120px);
        }
        &_column:nth-of-type(4) {
            @include display($flex-grow: 1, $flex-basis: 65px);
        }

        &_column {
            @include font($size: var(--exo-font-size-medium), $lineHeight: 19px, $weight: 700);
            background-color: var(--exo-color-background-secondary);
            padding: var(--exo-spacing-small);
        }
    }
}

.account-list {
    &_empty-state {
        display: flex;
        align-items: center;
        width: var(--exo-size-4);
        height: var(--exo-size-5);
        justify-content: center;
    }

    &__search {
        &-wrap {
            width: inherit;
        }

        &-label {
            font-size: var(--exo-font-size-large);
            margin-bottom: var(--exo-spacing-small);
        }

        &-input {
            overflow: hidden;
        }

        &-btn {
            margin-top: calc(var(--exo-spacing-x-large) + var(--exo-spacing-2x-small));
        }
    }

    &_count-label {
        font-size: var(--exo-font-size-micro);
        font-style: italic;
    }
}

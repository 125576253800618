@import 'assets/scss/index.scss';

.artifact-card {
    min-width: 18.75rem;

    &__content {
        height: 11.5625rem;
        margin-bottom: var(--exo-spacing-standard);
    }

    &__type {
        color: var(--exo-color-font-tertiary);
    }

    &__title {
        font: var(--exo-text-h3);
        margin: var(--exo-spacing-none);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__desc {
        margin-bottom: 0.25rem;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    &__readmore {
        font-weight: var(--exo-font-weight-semi-bold);
        font-size: var(--exo-font-size-x-small);
        text-decoration: underline;
    }

    &__actions {
        width:100%;
        display: flex;
        justify-content: end;

        &-installed {
            justify-content: flex-end;
        }

        &-uninstall {
            margin-right: var(--exo-spacing-x-small);
        }
    }
}

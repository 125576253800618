@import 'assets/scss/index.scss';

.envWrapper {
    @include display($display: flex, $align-items: center);
    gap: var(--exo-spacing-x-small);
    margin-top: var(--exo-spacing-standard);

    &_name {
        @include font($size: var(--exo-font-size-3x-large), $lineHeight: var(--exo-line-height-heading));
    }

    &_classification {
        @include font($size: var(--exo-font-size-small));
        color: $dimmed_text;
    }
}

.blocksWrapper {
    & > *:not(:first-child) {
        border-top: 1px solid $disabledBtn-color;
    }
}

.description {
    @include font($size: var(--exo-font-size-small), $lineHeight: var(--exo-line-height-heading));
    max-width: 520px;
}

.btnWrapper {
    display: flex;
    margin: var(--exo-spacing-medium) 0px;
    gap: var(--exo-spacing-medium);
    position: absolute;
    bottom: 0;
}

.btnIcon {
    margin-top: 12px;
    font-size: 16px;
}

.wrapper {
    &Actions {
        display: flex;
        margin-bottom: var(--exo-spacing-large);
        gap: var(--exo-spacing-small);

        &_container {
            @include tablet {
                @include display(flex, $flex-direction: column);
                & > * {
                    margin-bottom: var(--exo-spacing-small);
                }
            }
        }
    }
}

.genericContainerSubtitle{
    position: relative;
    z-index: 100000;
}
.genericContainer{
    position: relative;
    z-index: 1000000;
}



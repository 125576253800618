/**
* COLORS variables
*/

$primary-color: #1e1919;
$secondary-color: #000000;
$secondary-color-light: #616161;
$white: #ffffff;
$third-color: #0061ff;
$forth-color: #e5e5e5;
$forth-color-light: #f5f5f5;
$border-color: #808080;
$border-color-light: #ababab;
$border-color-medium: #c4c4c4;
$form-label-color: #1a1a1a;
$paginationBtn-color: #262626;
$disabledBtn-color: #cccccc;
$defaultPrimaryBtn-hover-color: #4b778b;
$defaultLightBtn-hover-color: #b6e5e2;
$defaultBtn-border-color: #033d58;
$cardPrimaryBtn-hover-color: #404040;
$cardLightBtn-border-color: #262626;
$btn-danger: #c73d58;
$alertBox-info-color: #f4c57f;
$alertBox-danger-color: #f8bec3;
$dimmed-text: #707070;
$scrollbar-color: #8c8c8c;
$scrollbarHover-color: #555555;
$scheduledIcon-color: #157f57;
$notScheduledIcon-color: #c73d58;
$link-color: rgba(18, 123, 135, 1);

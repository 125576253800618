@import 'assets/scss/index.scss';

.wrapper {
    @include display(flex);
    @include size($width: 100%, $height: 100vh);
    background: $white;

    .logo {
        width: 225px;
        padding: var(--exo-spacing-x-large) 0 var(--exo-spacing-3x-small);
    }

    .form {
        @include size($width: 540px, $height: 100vh);
        @include display(flex, $flex-direction: column);
        padding: 0 var(--exo-spacing-2x-large);

        @include tablet {
            width: 85%;
            padding: 0 var(--exo-spacing-large);
            max-width: initial;
        }

        &_description {
            font: var(--exo-text-h5);
            color: var(--exo-color-font);
        }

        &_element {
            svg {
                margin-top: var(--exo-spacing-2x-small);
            }
        }

        span + a {
            padding-left: var(--exo-spacing-2x-small);
        }
    }

    .footer {
        @include font(var(--exo-font-size-small));
        border-top: 1px solid $border-color-medium;
        margin-top: auto;

        a {
            @include font($color: var(--exo-color-font-link), $text-decoration: underline);
        }

        li {
            @include display(flex, $align-items: center);
            padding-bottom: var(--exo-spacing-2x-large);
            padding-top: var(--exo-spacing-medium);
        }

        li:first-child {
            padding-top: var(--exo-spacing-standard);
        }

        svg {
            color: var(--exo-color-background-selected);
            margin: 0 0 0 var(--exo-spacing-small);
        }
    }

    button {
        text-transform: initial;
        margin-top: var(--exo-spacing-large);
        width: 133px;
    }
}

.backgroundImgWrapper {
    @include size($height: 100vh, $max-width: calc(100% - 540px), $width: 100%);
    overflow-y: hidden;

    @include tablet {
        width: 15%;
        max-width: initial;
    }

    img {
        min-width: 100%;
    }
}

.ddWrapper {
    .ddList {
        .ddListSearchBar {
            max-height: calc(0.95 * var(--exo-size-5));
            overflow-y: auto;
        }
        &.searchable {
            overflow-y: hidden;
        }
    }

    .ddListHeader {
        display: flex;
        gap: var(--exo-spacing-standard);
        width: 100%;
    }

    .listItemContent {
        display: flex;
        align-items: center;
    }

    .listItemContentHead {
        width: var(--exo-spacing-x-large);
        height: var(--exo-spacing-x-large);
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--exo-color-background-info);
        font-weight: var(--exo-font-weight-bold);
        margin-right: var(--exo-spacing-medium);
        font-size: var(--exo-font-size-medium);
        color: var(--exo-color-font);
        flex-shrink: 0;
    }

    .listItemContentName {
        font-size: var(--exo-font-size-medium);
        color: var(--exo-color-font);
        font-weight: var(--exo-font-weight-semi-bold);
    }

    .listItemContentValue {
        font-size: var(--exo-font-size-micro);
        color: var(--exo-color-font-secondary);
    }
}

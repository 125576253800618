@import 'assets/scss/index.scss';

.dialog {
  &__title {
    font: var(--exo-text-h5);
    margin: var(--exo-spacing-none);
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

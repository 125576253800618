@import 'assets/scss/index.scss';

.field {
    &Block {
        label {
            @include font(14px, $primary-color, 600, $family: 'Fieldwork', $lineHeight: 22px);

            span {
                @include font(12px, $secondary-color-light, 400);
                padding-left: 4px;
            }
        }

        &_inputGroup {
            @include size($min-height: 32px, $max-width: 520px, $width: 100%);
            @include display(flex, space-between);

            input {
                @include size($min-height: 32px, $max-width: 520px);
                border: 1px solid $border-color;
                border-radius: 2px;
                padding: 0 8px;
                outline: none;
                flex-grow: 1;
            }
        }

        &_buttonGroup {
            @include display(flex);
            margin: var(---exo-spacing-x-small, 26px) 0 0 var(---exo-spacing-x-small, 8px);

            .generate-btn-icon {
                padding: 0 var(---exo-spacing-small, 12px);
            }
        }
    }

    &Error {
        @include font(12px, $btn-danger, 600, $style: italic);
        margin-top: 8px;
    }

    &ErrorBlock input {
        border: 1px solid $btn-danger;
    }
}
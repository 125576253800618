@import 'assets/scss/index.scss';

.tableWrapper {
    max-height: 500px;
    overflow-y: auto;

    /* firefox */
    scrollbar-color: $forth-color-light $scrollbar-color;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $forth-color-light;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-color;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbarHover-color;
    }
    
}

.table {
    @include size($width: 100%, $height: 100%, $max-width: 978px);
    border-collapse: separate;
    white-space: nowrap;
    margin-bottom: 40px;

    &Header {
        font-weight: 700;
        background-color: $forth-color;
        border: 1px solid $forth-color-light;
        padding: 6px 10px 7px 8px;
        height: 31px;
    }

    &Row {
        &Fields {
            & > :first-of-type {
                margin-right: 15px;
            }
        }

        &_field {
            font-weight: 700;
        }

        &_dimmed {
            color: $dimmed-text;
        }

        &_underlined {
            text-decoration: underline;
            cursor: pointer;
        }

        &_readOnly {
            pointer-events: none;
        }
    }

    span + .tableRow_transformed {
        display: inline-block;
    }

    .tableRow_hidden {
        display: none;
    }

    .tableRow_transformed + .tableRow_transformed {
        display: block;
        text-transform: capitalize;
    }
}
.browse {
    &-toolbar {
        margin: var(--exo-spacing-x-large) 0;
        align-items: center;

        &__search {
            height: var(--exo-spacing-x-large);
        }

        &__switch {
            display: flex;
            align-items: center;
            margin-left: var(--exo-spacing-standard);
        }

        &__artifact-count {
            text-align: right;
        }
        &__switch-label {
            font-weight: var(--exo-font-weight-semi-bold);
        }
    }

    &-loader {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: calc(100vh - 4.5rem);
    }
    &-off-text {
        font-size: var(--exo-font-size-small);
    }
}

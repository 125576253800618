@import 'assets/scss/index.scss';


.dropdownWrapper {
    @include display($display: flex, $flex-direction: column);

    &_multiple {
        margin-left: 10px;
    }

    &Label {
        @include display(flex, $align-items: center);
        padding-bottom: var(--exo-spacing-small);

        &_title {
            @include font($family: 'Fieldwork', $weight: 600, $size: 14px, $lineHeight: 24px);
        }

        &_required {
            @include font(12px, $secondary-color-light, 400);
            padding-left: 4px;
        }
    }
}

select.dropdown {
    padding: 0 8px;
}

.processDropdown {
    select {
        width: max-content;
        max-width: 90%;
    }
}



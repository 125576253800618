@import 'assets/scss/index.scss';

.field {
    &Block {
        label {
            @include font(14px, $primary-color, 600, $family: 'Fieldwork', $lineHeight: 22px);

            span {
                @include font(12px, $secondary-color-light, 400);
                padding-left: 4px;
            }
        }

        &_inputGroup {
            @include size($min-height: 32px, $max-width: 520px, $width: 100%);
            @include display(flex, space-between);

            input {
                @include size($min-height: 32px, $max-width: 520px);
                border: 1px solid $border-color;
                border-radius: var(--exo-radius-small);
                padding: 0 var(--exo-spacing-x-small);
                outline: none;
                flex-grow: 1;
            }
        }

        &_buttonGroup {
            @include display(flex);
            @include size($min-height: 32px, $max-width: 220px, $width: 100%);
            margin: 0 0 var(---exo-spacing-x-small, 26px) 0;
            gap: var(---exo-spacing-small, 12px);
        }
        &_encryptField {
            @include display(flex);
            width: auto;
            gap: var(--exo-spacing-small, 12px);
        }
        &_encryptInputField {
            @include size($min-width: 340px);
        }
        &_encryptMessage {
            @include font(
                $size: var(--exo-font-size-small),
                $weight: var(--exo-font-weight-regular),
                $color: var(----exo-color-font-font-secondary, #707070),
                $lineHeight: (
                    --exo-line-height-body,
                )
            );
            margin-bottom: 26px;
            margin-top: -20px;
        }
        &_encryptErrorMessage {
            @include font(
                $size: var(--exo-font-size-small),
                $weight: var(--exo-font-weight-regular),
                $color: var(----exo-color-font-font-secondary, #c73d58ff),
                $lineHeight: (
                    --exo-line-height-body,
                )
            );
            margin-bottom: 26px;
            margin-top: -20px;
        }

        &_buttonEncrypted {
            @include display(inline-block);
            width: 'auto';
            margin-top: var(---exo-spacing-x-small, 24px);
        }
    }
}

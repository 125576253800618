@import 'assets//scss/index.scss';

.banner {
    background: $primary-color;
    padding: 40px 0;
    width: 100vw;
    overflow: hidden;

    @include mobile {
        padding: 16px 0;
    }
    
    &Title {
        @include size($width: 100%, $max-width: 1133px);
        @include font($font-size--lg, $white, null, capitalize);
        margin: 0 auto;
        padding: 0 10px;

        @include desktopSmall {
            padding: 0 30px;
        }

        @include mobile {
            @include font($font-size--md, $white, null, capitalize);
        }
    }
}
@import 'assets/scss/index.scss';

.dialog {
  &Actions {
    display: flex;
    margin-top: 20px;

    &_left {
      display: flex;
      margin-right: auto;
      gap: 16px;
    }

    &_right {
      display: flex;
      margin-left: auto;
      gap: var(--exo-spacing-small);
    }

    &_center {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}

.loadingContent {
  @include display(flex, center, center, $flex-direction: column);

  & img {
    @include size(38px, 38px);

    svg {
      color: $dimmed-text;
    }
  }

  & h2 {
    @include font(25px, $paginationBtn-color, 400, $lineHeight: 34px, $family: 'Fieldwork', $text-align: center);
    display: flex;
  }
}

.modalOverlay {
  @include size(100%, 100%);
  @include position(fixed, $top: 0, $left: 0);
  @include display(flex, center, center);
  background: rgba($secondary-color-light, 0.4);
  z-index: 10;
}

.dropdown {
  @include display(flex, $flex-direction: column);
  position: relative;

  label {
    @include font(14px, $secondary-color, 600, $lineHeight: 21px, $family: 'Open Sans');
    margin-bottom: 4px;
  }

  &Field {
    @include display(flex, $align-items: center);
    position: relative;
    cursor: pointer;

    input {
      @include font(14px, $textTransform: capitalize, $lineHeight: 20px, $family: 'Open Sans');
      padding: 4px 8px;
      width: 100%;
      border: 1px solid $cardPrimaryBtn-hover-color;
      border-radius: 2px;
      cursor: pointer;
    }

    &_icon {
      @include position(absolute, $right: 2px);
    }
  }

  &Options {
    @include position(absolute, $right: 0, $left: 0);
    background: $white;
    padding: 24px;
    border: 1px solid $scrollbar-color;
    border-top: none;

    &Category {
      @include display(flex, $align-items: center);
      @include font(14px, $paginationBtn-color, 600, capitalize, 21px, 'Open Sans');

      &_line {
        @include size(1px, 100%);
        background: $disabledBtn-color;
        flex-grow: 1;
        margin-left: 8px;
      }
    }

    &Item {
      @include font(14px, $paginationBtn-color, 400, capitalize, 21px, 'Open Sans');
      margin: 16px 0;
      cursor: pointer;
    }
  }
}

.onlyLoader {
  & img {
    @include size(72px, 72px);
    border-radius: 5px;

    svg {
      color: $dimmed-text;
    }
  }
}

.errorMessage {
  @include font(12px, $btn-danger, 400, $lineHeight: 18px, $family: 'Open Sans');
  margin-top: 4px;
}

.dialog-box {
  &__actions {
    display: flex;

    &-installed {
      justify-content: flex-end;

    }
  }
}
.dialog_body {
  max-width: 552px;
}
@import 'assets/scss/index.scss';

.footer {
    &-wrapper {
        border-top: 1px solid $border-color-medium;
        background: var(--exo-color-body);
        height: 32px;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100vw;
        font-size: var(--exo-font-size-micro);
        z-index: 99999;
        > div {
            width: 100%;
        }
    }

    &__nav {
        display: flex;
        height: 100%;
        align-items: center;
        padding: var(--exo-spacing-small);
        justify-content: end;
        color: var(--exo-color-font-link);
    }
    &__nav-divider {
        width: var(--exo-spacing-4x-small);
        height: var(--exo-spacing-medium);
        background: var(--exo-color-scrim);
        margin: 0  var(--exo-spacing-medium); 
    }
}

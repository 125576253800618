.confirmation {
    display: flex;
    margin-right: var(--exo-spacing-standard);


&-icon {
       font-size: var(--exo-font-size-3x-large);
        margin-right: var(--exo-spacing-standard);
    }
    &-success {
      margin-bottom: var(--exo-spacing-2x-large);
     }
}
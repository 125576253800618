@import 'assets/scss/index.scss';
.search-dropdown {
    &-wrapper {
        @include display(flex, flex-start);
        gap: var(--exo-spacing-standard);

        @include mobile {
            flex-direction: column;
            & > * {
                margin-bottom: 8px;
            }
        }
        &__input {
           min-width: 50%;
        }
        &__dropdown {
            width: 8rem;
            margin-left: var(--exo-spacing-medium);
        }
    }
}

.activity {
    display: flex;
    gap: var(--exo-spacing-3x-small);
}

.icon {
    font-size: var(--exo-font-size-2x-large);
    margin-left: auto;
}
.divider-line {
    margin: var(--exo-spacing-x-small);
}
.description {
    font-size: var(--exo-font-size-small);
    margin-top: var(--exo-spacing-x-micro);
}

@import 'assets/scss/index.scss';

.tableWrapper {
    margin-bottom: var(--exo-spacing-x-small);

    /* firefox */
    scrollbar-color: $forth-color-light $scrollbar-color;

    &::-webkit-scrollbar {
        width: var(--exo-spacing-x-small);
    }

    &::-webkit-scrollbar-track {
        background: $forth-color-light;
        border-radius: var(--exo-spacing-2x-large);
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-color;
        border-radius: var(--exo-spacing-2x-large);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbarHover-color;
    }

    
}

.gripTableWrapper {
    margin-bottom: var(--exo-spacing-x-small);
    overflow-y: scroll;
    max-height: calc(50vh - 200px);

     /* firefox */
     scrollbar-color: $forth-color-light $scrollbar-color;

    &::-webkit-scrollbar {
        width: var(--exo-spacing-x-small);
    }

    &::-webkit-scrollbar-track {
        background: $forth-color-light;
        border-radius: var(--exo-spacing-2x-large);
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-color;
        border-radius: var(--exo-spacing-2x-large);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbarHover-color;
    }

   
}

.alertContainer {
    margin-top: var(--exo-spacing-medium);
}

.table {
    @include size($width: 100%, $max-width: 520px);
    border-collapse: collapse;
    margin-bottom: 16px;
    white-space: normal;
    table-layout: fixed;

    &Row,
    &Checkbox {
        font-weight: 400;
        border-bottom: 1px solid $disabledBtn-color;
        padding: 10px 10px 10px 8px;
    }

    &Row {
        max-width: 490px;
        position: relative;
        word-wrap: normal;
        word-break: break-word;
        white-space: normal;

        span {
            display: inline-block;
        }
    }

    &Header {
        font-weight: 700;
        background-color: $forth-color;
        border: 1px solid $forth-color-light;
        padding: 6px 10px 7px 8px;

        &.tableCheckbox {
            border-right: 2px solid $forth-color-light;
        }
    }

    &Checkbox {
        width: 33px;
        box-sizing: border-box;
    }

    &Item {
        padding-right: 8px;
    }

    &Item_bold {
        font-weight: 700;
    }

    &Checkbox + &Row {
        padding-left: 22px;
    }

    &Label {
        font-size: var(--exo-font-size-large);
        margin-bottom: var(--exo-spacing-small);
    }

    td {
        border-collapse: collapse;

        [role='tooltip'] {
            position: absolute;
            top: -15px;
            left: 23px;
            white-space: nowrap;
        }

        svg:hover + [role='tooltip'] {
            display: block;
        }
    }
}

.tableCheckbox_input {
    @include size(18px, 18px);
    appearance: none;
    background-color: $white;
    margin: 0;
    border: 2px solid $border-color;
    border-radius: 4px;
    display: grid;
    place-content: center;

    &:checked {
        background-color: $form-label-color;
        border: 2px solid $form-label-color;
    }

    &:before {
        @include size(12px, 12px);
        content: '';
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 14px 14px $white;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked:before {
        transform: scale(1);
    }
}

.fieldError {
    @include font(12px, $btn-danger, 600, $style: italic);
    margin-top: 8px;
}

.generic-container {
    &-header {
        &__name {
            font-weight: var(--exo-font-weight-semi-bold);
            font-size: var(--exo-font-size-small);
            color: var(--exo-color-font-tertiary);
            line-height: var(--exo-line-height-body);
            margin-bottom: var(--exo-spacing-x-small);
        }
        &__title {
            font: var(--exo-text-h3);
            line-height: var(--exo-line-height-heading);
            color: var(--exo-color-font);
        }
    }
    &-footer {
        margin: var(--exo-spacing-2x-large) 0;
        &__actions {
            display: flex;
            gap: var(--exo-spacing-small);
        }
    }
    .loader {
        &-wrapper {
            position: fixed;
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
        }
    }

    &-subtitle {
        font-size: var(--exo-font-size-large);
        margin-bottom: var(--exo-spacing-small);
    }
}

.env-selection-table-wrapper {
    max-width: 520px;
}

@import 'assets/scss/index.scss';

.datePicker {
        @include size($height: 32px, $max-width: 120px);
        @include display($display: flex, $align-items: center, $justify-content: center);
        @include font($family: 'Open Sans', $size: 14px, $lineHeight: 24px);
        padding: 4px 8px;
        border: 1px solid $border-color;
        gap: 7px;
        border-radius: 2px;     
        &_icon {
                margin-top: 4px;       
                 
                svg {
                        color: $paginationBtn-color;
                }
        }
}

.wrapper {
        &_title {
                @include font($size: 14px, $lineHeight: 24px);
        }
}


@font-face {
    font-family: "fieldwork";
    src: url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: "fieldwork";
    src: url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/eff0f3/00000000000000007735ce9f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }
  
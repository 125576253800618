@mixin mobile {
    @media (max-width: $mobileBreakpoint) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tabletBreakpoint) {
        @content;
    }
}

@mixin desktopSmall {
    @media (max-width: $desktopSmallBreakpoint) {
        @content;
    }
}

@mixin exoMobile {
    @media (max-width: calc($exoTabletBreakpoint - 1px)) {
        @content;
    }
}

@mixin exoTablet {
    @media (min-width: $exoTabletBreakpoint) and (max-width: calc($exoDesktopSmallBreakpoint - 1px)) {
        @content;
    }
}

@mixin exoDesktop {
    @media (min-width: $exoDesktopSmallBreakpoint) {
        @content;
    }
}

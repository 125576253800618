@import 'assets//scss/index.scss';

.btn {
    @include font($lineHeight: 16px, $color: $defaultBtn-border-color, $size: 16px, $weight: 600, $family: 'Fieldwork' );
    padding: 13px 20px;
    text-transform: uppercase;
    min-width: 22px;
    cursor: pointer;
    border: 1px solid $defaultBtn-border-color;
    background: $white;
    transition: 0.1s ease-in-out;

    &Primary {
        color: $white;
        background: $defaultBtn-border-color;

        &:hover {
            background: $defaultPrimaryBtn-hover-color;
        }
    
        &:focus {      
            background: $defaultPrimaryBtn-hover-color;
            border: 1px solid $white;
            outline: 2px solid $defaultBtn-border-color;        
        }
    }

    &Light {
        &:hover {
            background: $defaultLightBtn-hover-color;
        }
    
        &:focus {      
            border: 1px solid $white;
            outline: 2px solid $defaultBtn-border-color;        
        }
    }

    &CardPrimary {
        color: $white;
        background: $primary-color;
        border: 1px solid $primary-color;
        outline: 2px solid $primary-color;        

        &:hover {
            background: $cardPrimaryBtn-hover-color;
            border: 1px solid $white;
            outline: 2px solid $cardPrimaryBtn-hover-color;        
        }
    
        &:focus {    
            background: $cardPrimaryBtn-hover-color;
            border: 1px solid $white;
            outline: 2px solid $primary-color;        
        }
    }

    &CardLight {
        color: $cardLightBtn-border-color;
        border: 1px solid $white;
        outline: 1px solid $cardLightBtn-border-color;        

        &:hover {
            background: $defaultLightBtn-hover-color;
            border: 1px solid $cardLightBtn-border-color;
            outline: 2px solid $defaultLightBtn-hover-color;        
        }
    
        &:focus {    
            color: $white;
            background: $cardPrimaryBtn-hover-color;
            border: 1px solid $white;
            outline: 2px solid $primary-color;        
        }
    }

    &FillSpace {
        flex: 1;
    }

    &Single {
        margin-left: auto;
        min-width: 152px;
    }

    &Danger {
        background: $btn-danger;
        color: $white;
        
        &:hover,
        &:focus {
            color: $white;
            background: #FFBEB3;
            border: 1px solid $white;
            outline: 2px solid $btn-danger;
        }
    }

    &Refresh {
        @include display($display: flex, $align-items: center);
        @include font($family: 'Open Sans', $weight: 600, $size: 16px, $lineHeight: 24px, $textTransform: capitalize);
        color: $paginationBtn-color;
        border-color: $border-color;
        max-height: 32px;
    }
}

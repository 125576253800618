.edit {
    &-table {
        width: 726px;
        color: var(--exo-color-font);
        &-head {
            &__row {
                background-color: var(--exo-color-background-secondary);
                &-data {
                    font: var(--exo-text-label-standard-semi-bold);
                    height: calc(var(--exo-size-1) / 2);
                    &:nth-child(1) {
                        width: 320px;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        width: 200px;
                    }
                }
            }
        }
        &-body {
            padding: 0;
            display: flex;
            width: fit-content;
        }
        &__row {
            border-radius: none;
            &-data {
                background: none;
                border-bottom: 1px solid var(--exo-color-background-disabled);
                font-family: var(--exo-font-family);
                font-style: normal;
                font-weight: var(--exo-font-weight-regular);
                font-size: var(--exo-font-size-small);

                a:link,
                a:visited {
                    text-decoration: underline;
                    display: inline-block;
                    margin-right: var(--exo-spacing-medium);
                    cursor: pointer;
                }
                &:nth-child(1) {
                    width: 320px;
                }
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    width: 200px;
                }

                &-name {
                    padding-right: var(--exo-spacing-standard);
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .status-icon {
            font-size: var(--exo-font-size-3x-large);
            cursor: pointer;
        }
    }

    &-footer {
        display: flex;
    }
}

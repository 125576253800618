.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 315px));
  column-gap: 28px;
  row-gap: 28px;
}

.empty-state{
  display:flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: calc( 100vh - 10rem );
}
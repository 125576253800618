@import 'assets/scss/index.scss';

.wrapper {
    @include size($max-width: 1156px, $width: 100%, $min-height: calc(100vh - 240px));
    background: $white;
    border-top: 10px solid $third-color;
    margin: 13px auto 40px;
    padding-bottom: 60px;
    position: relative;

    @include tablet {
        padding-bottom: 0;
    }

    &Body {
        padding: 0 40px 60px;

        @include tablet {
            padding-bottom: 0;
        }
    }

    &Title {
        @include font(25px, $paginationBtn-color, 400, $family: 'Fieldwork', $lineHeight: 34px);
        padding: 23px 40px 15px;
        margin-bottom: 25px;
        border-bottom: 1px solid $border-color-light;

        &_subtitle {
            max-width: 520px;
            margin-top: 8px;
        }
    }

    &Actions {
        width: 100%;

        &_container {
            display: flex;
        }
    }

    .extensionTitle {
        cursor: pointer;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 20px;
            transition: transform 300ms;
            border: solid #262626;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            margin-right: 15px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }

    .extensionTitleOpened {
        &:after {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }
    }
}

.subtitle {
    font: var(--exo-text-h6);
    color: var(--exo-color-font);
    margin-bottom: var(--exo-spacing-small);
}

.genericTitle {
    @include size($max-width: 1156px, $width: 100%);
    @include font(28px, $paginationBtn-color, 400, $family: 'Fieldwork', $lineHeight: 38px);
    margin: 20px auto 0;
    word-wrap: break-word;

    @include desktopSmall {
        padding: 0 40px;
    }
}

.containerConnection {
    @include font(20px, $paginationBtn-color, 400, $family: 'Fieldwork', $lineHeight: 27px);
    margin-top: 20px;
    font-size: 20px;
    color: #262626;
    font-weight: 400;
    border: 1px solid #cccccc;
    background: #e5e5e5;
    padding: 12px 16px;
    line-height: 18px;
}

.extraInfo {
    @include display(flex, $align-items: flex-end);
    margin-bottom: 10px;
    span {
        color: $paginationBtn-color;
        margin-right: 6px;
    }
}

.mapTitle {
    margin-bottom: 10px;
}

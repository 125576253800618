@import 'assets/scss/index.scss';

.historyFilters {
    @include display($display: flex, $align-items: flex-start, $justify-content: space-between);
    max-width: 60%;
    gap: var(--exo-spacing-standard);
}

.historyFilters_btn {
    margin-top: calc(var(--exo-spacing-2x-small) + var(--exo-spacing-large));
}
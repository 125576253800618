@import 'assets/scss/index.scss';

.searchBarWrapper {
    @include display(flex, $flex-direction: column, $flex-grow: 1);
    
    @include mobile {
        margin-right: 0;
    }
}

.searchBar {
    @include size($height: 32px, $width: 353px);
    @include display($display: flex, $align-items: center);
    border-radius: 2px;
    border: 1px solid $border-color;
    padding: 0 12px 0 9px;
    background: $white;
    box-sizing: border-box;
    
    .searchBar_input {
        @include size($width: 100%);
        border: none;
        outline: none;
    }

    svg {
        opacity: .7;
    }
}

.searchBarLarge {
    @include size($height: 32px , $width: 100% );
    flex-direction: row-reverse;
    
    @include desktopSmall {
        width: 100%;
    }

    & > .searchBar_input {
        @include font($size: 14px, $lineHeight: 24px );
        margin-left: 5px;
    }
}

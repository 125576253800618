@import './fonts.scss';
@import 'variables/colors.scss';

* {
    box-sizing: border-box;
}

body {
    width: 100%;
    overflow-x: hidden;
    color: var(--exo-color-font);
    font-family: var(--exo-font-family);
    line-height: var(--exo-line-height-body);
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &Wrapper {
        max-width: 1384px;
        width: 100%;
        margin: 0 28px;
    }
}

.Toastify {
    div {
        font-size: var(--exo-font-size-small);
        line-height: var(--exo-line-height-body);
        color: $secondary-color-light;
    }
}

.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--exo-color-scrim);
    z-index: var(--exo-z-index-dialog);
}

.ex-col-any {
    padding: calc(var(--exo-spacing-large) * 0.5);
}

.link {
    cursor: pointer;
    text-decoration: underline;
    font: var(--exo-text-link-standard);
    color: var(--exo-color-font-link);
}

.ex-select-override {
    z-index: 99;
}

.componentExTooltip {
    width: 100%;
    z-index: 9999999999;
    word-break: break-word;
}

.componentExTooltipDiv {
    white-space: initial;
    word-break: break-word;
}
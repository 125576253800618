@import 'assets/scss/index.scss';

.container {
    @include font(14px, var(--exo-color-font), 700);
    padding: var(--exo-spacing-standard);
    padding-left: var(--exo-spacing-3x-large);
    border-bottom: 1px solid var(--exo-color-background-disabled);
    max-width: 70%;
}

.details {
    @include display(flex, flex-start);
    width: 100%;

    &Item {
        @include display(flex, $flex-direction: column, $flex-grow: 1);

        &_value {
            font-weight: var(--exo-font-weight-regular);
        }
    }
}

.message {
    @include display(flex, $flex-direction: column);
    width: 100%;
    margin-top: var(--exo-spacing-medium);

    &Content {
        @include display(flex, $flex-direction: column);
        font-weight: var(--exo-font-weight-regular);
    }
}

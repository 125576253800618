@import 'assets//scss/index.scss';

.wrapper {
    @include display(flex, center, center, $flex-direction: column);
    padding-top: 125px;
    
    &Title {
        @include font(23px, $paginationBtn-color, $lineHeight: 32px, $family: 'Fieldwork');
    }
    
    &Description {
        margin: 8px 0 29px;
    }

    svg {
        font-size: 24px;
        margin-bottom: 15px;
    }
}

@import 'assets/scss/index.scss';

.tableRow {
    display: flex;
    margin-top: var(--exo-spacing-2x-small);
    overflow: hidden;
    border-bottom: 1px solid var(--exo-color-background-disabled);
    border-radius: 0;
    transform: border var(--exo-time-standard) ease;
    font-size: var(--exo-font-size-small);
    cursor: pointer;

    &_icon {
        display: flex;
        align-items: center;
        margin: 0 var(--exo-spacing-medium);

        &Text {
            font-size: var(--exo-font-size-small);
        }
    }
    &_name {
        @include display($align-items: center, $flex-grow: 2, $flex-basis: 115px);
        padding: var(--exo-spacing-medium) var(--exo-spacing-x-small);
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        &_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }      
        &:hover + [role='tooltip'],
        &:focus + [role='tooltip'] {
            display: block;
        }
    }

    &_status {
        @include display($display: flex, $align-items: center, $flex-grow: 1, $flex-basis: 120px);
        gap: 5px;
        padding: 10px 10px 10px 8px;

        span {
            @include display($display: flex, $align-items: center);
            gap: 5px;
        }
    }

    &_actions {
        @include display($display: flex, $align-items: center, $flex-grow: 1, $flex-basis: 65px);
        gap: 15px;
        padding: 10px 10px 10px 8px;
        text-decoration: underline;
    }

    &_timeStamp {
        @include display($display: flex, $align-items: center, $flex-grow: 1, $flex-basis: 65px);
        gap: 15px;
        padding: 10px 10px 10px 8px;
    }

    &_runNow {
        cursor: pointer;
    }

    &_schedule {
        cursor: pointer;
    }
}

.successIcon {
    font-size: var(--exo-font-size-3x-large);
}

.failIcon {
    font-size: var(--exo-font-size-3x-large);
}

[role='tooltip'] {
    display: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: #262626;
    background-color: #d2dadc;
    padding: 4px;
    padding: 10px;
    position: absolute;
    top: 40px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    max-width: 100%;
    z-index: 10;
}

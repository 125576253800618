@import 'assets//scss/index.scss';

.wrapper {
    @include size(46px, 44px);
    background: $white;
    cursor: pointer;
    border: 1px solid $white;
    outline: 1px solid $btn-danger;
    transition: 0.1s ease-in-out;

    svg {
        color: $btn-danger;
    }
    &:hover,
    &:focus {
        color: $white;
        background: #FFBEB3;
        border: 1px solid $white;
        outline: 2px solid $btn-danger;
    }
}

.withLabelWrapper {
    @include size(32px, 90px);
    @include display(flex, center, center);
    @include font(14px, $primary-color, 600, uppercase , 22px, 'Fieldwork');
    background: $white;
    border: 1px solid $primary-color;
    cursor: pointer;
    padding: 4px 8px;
    gap: 4px;

    & svg {
        color: $primary-color;
    }

    & span {
        margin-top: 4px;
    }
}  

.largeBtn {
    @include size($height: 44px, $width: 185px);
    @include font($size: 12px, $family: 'Fieldwork', $weight: 600, $lineHeight: 22px);
    background-color: $white;
    border: 1px solid $paginationBtn-color;
    cursor: pointer;
}

.btnContent {
    @include display($display: flex, $align-items: center, $justify-content: center);
    gap: 7px;
    
    p {
        margin-top: 3px;
        font-size: 16px;
    }
}